import { Injectable } from '@angular/core';
import { Theme } from '@devop/ngx-metronic/base-layout';

export type User = {
  id: number;
  avatarUrl?: string | null;
  firstName: string;
  lastName: string;
  role?: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly server = !('localStorage' in globalThis);

  setUser(user: User | null) {
    if (this.server) {
      return;
    }
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    if (this.server) {
      return null;
    }
    const str = localStorage.getItem('user');
    if (str) {
      return JSON.parse(str) as User;
    }
    return null;
  }

  setStyle(style: Theme) {
    if (this.server) {
      return;
    }
    localStorage.setItem('theme', style);
  }

  getStyle(): Theme {
    if (this.server) {
      return Theme.AUTO;
    }
    return (localStorage.getItem('theme') as Theme) || Theme.AUTO;
  }
}
