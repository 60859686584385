import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import {
  CurrentUserGQL,
  LoginGQL,
  LoginWithGoogleGQL,
  LogoutGQL,
} from '@project/admin/generated/graphql';
import { LocalStorageService, User } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user: User | null = null;
  private checkPromise: Promise<User | null> | null = null;

  get isAdmin() {
    return this._user?.role === 'admin';
  }

  get user() {
    return this._user;
  }

  private set user(value) {
    this._user = value;
    this.localStorageService.setUser(value);
  }

  get requireUser() {
    if (!this._user) {
      throw new Error('User not set');
    }
    return this._user;
  }

  constructor(
    private readonly loginGQL: LoginGQL,
    private readonly logoutGQL: LogoutGQL,
    private readonly currentUserGQL: CurrentUserGQL,
    private readonly loginWithGoogleGQL: LoginWithGoogleGQL,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.load();
  }

  private async load() {
    try {
      this._user = this.localStorageService.getUser();
      if (!this.user || (await this.check())) {
        return;
      }
      const snapshot = this.activatedRoute.snapshot;
      const redirect =
        snapshot.queryParams['redirect'] ?? snapshot.url.toString();
      this.router.navigate(['/', 'auth'], {
        queryParams: {
          redirect,
        },
      });
    } catch (e) {
      console.error(e);
      this.user = null;
    }
  }

  check() {
    return (
      this.checkPromise ||
      (this.checkPromise = firstValueFrom(
        this.currentUserGQL.fetch({}, { fetchPolicy: 'network-only' })
      )
        .then((result) => (this.user = result.data.currentUser ?? null))
        .catch(() => null)
        .finally(() => (this.checkPromise = null)))
    );
  }

  async login(
    username: string,
    password: string,
    options?: { redirect?: string | null }
  ) {
    if (this.user) {
      await this.logout(null);
    }
    const result = await firstValueFrom(
      this.loginGQL.mutate({
        password,
        username,
      })
    );
    return this.postLogin(result.data?.login?.user, options);
  }

  async loginWithGoogle(token: string, options?: { redirect?: string | null }) {
    if (this.user) {
      await this.logout(null);
    }
    const result = await firstValueFrom(
      this.loginWithGoogleGQL.mutate({
        token,
      })
    );
    return this.postLogin(result.data?.loginWithGoogle?.user, options);
  }

  async logout(redirect?: string | null) {
    if (!this.user) {
      return false;
    }
    this.user = null;
    if (redirect !== null) {
      this.router.navigateByUrl(redirect ?? '/auth');
    }
    await firstValueFrom(this.logoutGQL.mutate());
    return true;
  }

  private async postLogin(user?: User, options?: { redirect?: string | null }) {
    if (!user) {
      throw new Error('Failed to login');
    }
    const redirect = options?.redirect;
    this.user = user;
    if (redirect !== null) {
      await this.router.navigateByUrl(redirect ?? '/');
    }
    return user;
  }
}
